import React from 'react'
import {
    Box,
    Container,
    Heading,
    Flex,
    Text
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import IrisBackground from '../../../images/iris-background.svg'
import styled from '@emotion/styled'

const BackgroundSection = styled.div`
    background-image: url(${IrisBackground});
    width: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 50%;
`;

const FlexLeadTechnology = (
    <Box>
        <Container maxW="container.sm">
            <Flex w="100%" justifyContent="center" mb="8">
                <Box w="100%">
                    <StaticImage src="../../../images/flex-leads.jpg" layout="fullWidth" placeholder="blurred" alt="Flex Leads" />
                </Box>
            </Flex>
            </Container>
            <BackgroundSection>
                <Container maxW="container.sm" mb="20">
                    <Heading as="h1" className="card-title">
                        Flex-Lead Technology
                    </Heading>
                    <Heading as="h2" className="card-subtitle">
                    Printed Polymer Leads for Superior Neural Access
                    </Heading>
                    <Text>IRIS Biomedical’s printed polymer Flex-Lead technology was developed specifically for novel Neuromodulation applications. The contact end of the leads are made entirely from platinum- doped, biocompatible polymer and that can be printed in a form factor specifically designed for the target neural structure. The leads are soft and flexible and easily conform to the natural shape of neural tissue. Flex-Leads are highly fatigue resistant and offer superior mechanical performance to traditional wire-based SCS leads.</Text>
                    <Text style={{fontWeight: 700, fontSize: '1.25rem'}}>
                        Features
                    </Text>
                    <ul style={{marginLeft: '1rem'}}>
                        <li>Biocompatible material composition for long-term implantation.</li>
                        <li>Thin, flexible form-factor to minimize impact on neural tissue.</li>
                        <li>Customizable geometry to target specific neural anatomy.</li>
                        <li>Wire-free construction to reduce stiffness and improve durability.</li>
                    </ul>
                    <Text fontStyle="italic">
                    Flex-Lead technology has not been evaluated by the FDA.
                    </Text>
                </Container>
                </BackgroundSection>
                <Box>
                    <Container maxW="container.sm">
                        <Flex mb="20">
                            <Box p="4" w="50%">
                                <StaticImage src="../../../images/flex-leads-1.jpg" alt="Flex Leads" layout="fullWidth" placeholder="blurred" />
                                <Text fontStyle="italic">
                                    More flexible and durable than traditional SCS lead materials
                                </Text>
                            </Box>
                            <Box p="4" w="50%">
                                <StaticImage src="../../../images/flex-leads-2.jpg" alt="Flex Leads" layout="fullWidth" placeholder="blurred" quality="8" />
                                <Text fontStyle="italic" maxW="80%">
                                    Ideal for cuffs and other non-planar lead geometries
                                </Text>
                            </Box>
                        </Flex>
                            <Box >
                                <Box mb="10">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        FLEX-LEAD TECHNOLOGY
                                    </Heading>
                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" borderBottom="2px solid black" marginBottom="0.5rem" fontSize="1.25rem">
                                        Specifications
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Thickness: </strong>
                                            </td>
                                            <td>
                                                75μm (per layer)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Minimum Contact Pitch: </strong></td>
                                            <td>0.5mm</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Minimum Contact Size:</strong>
                                            </td>
                                            <td>
                                            100μm
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>DC Impedance:</strong>
                                            </td>
                                            <td>DC Impedance:</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>AC Impedance:</strong>
                                            </td>
                                            <td>4kΩ at 1kHz (for 1mm contact)</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Connector:</strong>
                                            </td>
                                            <td>Cylindrical SCS-Style</td>
                                        </tr>
                                    </table>

                                </Box>
                            </Box>
                            <Flex mb="20">
                            <Box w="50%">
                                <StaticImage src="../../../images/flex-leads-3.jpg" alt="High density option" layout="fullWidth" placeholder="blurred" />
                            </Box>
                            <Box p="4" w="50%">
                                <Flex flexDirection="column" justifyContent="center" h="100%">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        High-Density Options Available
                                    </Heading>
                                </Flex>
                            </Box>
                        </Flex>

                    </Container>
                </Box>
            
    </Box>
)

export default FlexLeadTechnology