import React from 'react'
import {
    Box,
    Container,
    Heading,
    Flex,
    Text
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import IrisBackground from '../../../images/iris-background.svg'
import styled from '@emotion/styled'

const BackgroundSection = styled.div`
    background-image: url(${IrisBackground});
    width: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 50%;
`;

const PowerDataSubsystems = (
    <Box>
        <Container maxW="container.sm">
            <Flex w="100%" justifyContent="center" mb="8">
                <Box w="85%">
                    <StaticImage src="../../../images/mira@2x.png" layout="fullWidth" placeholder="blurred" alt="Mira" />
                </Box>
            </Flex>
            </Container>
            <BackgroundSection>
                <Container maxW="container.sm" mb="20">
                    <Heading as="h1" className="card-title">
                        Mira
                    </Heading>
                    <Heading as="h2" className="card-subtitle">
                    32-Channel Neural Sensing Platform
                    </Heading>
                    <Text>
                    Showcasing IRIS Biomedical’s implantable sensing technologies, the MIRA is designed to provide high-fidelity access to neural signals for a wide range of applications including closed-loop neuromodulation, neuroprosthetics, and brain-computer-interfacing (BCI).</Text>
                    <Text>The MIRA’s industry-leading sensing technology is capable of measuring evoked neural signals (ECAPs), as well as endogenous neural activity, including even the extremely small action potential signals generated by individual neurons.

                    </Text>
                    <Text style={{fontWeight: 700, fontSize: '1.25rem'}}>
                        Features
                    </Text>
                    <ul style={{marginLeft: '1rem'}}>
                        <li>Ceramic enclosure for long-term implantation. (Other enclosure materials available).</li>
                        <li>RF powered to reduce size while increasing longevity and safety.</li>
                        <li>Infrared light (IR) and reflected impedance (RI) options for data telemetry.</li>
                        <li>Compatible with various clinical leads/electrodes to support a wide range of sensing applications.</li>
                    </ul>
                    <Text fontStyle="italic">
                        The MIRA has been used in FDA-approved Investigational Studies.
                    </Text>
                </Container>
                </BackgroundSection>
                <Box>
                    <Container maxW="container.sm">
                        <Flex mb="20">
                            <Box p="4" w="50%">
                            <Text fontStyle="italic" maxW="80%">
                                Built on IRIS Biomedical’s 32-Ch Sensing ASIC
                            </Text>
                                <StaticImage src="../../../images/32Ch-sensing.jpg" alt="32-Ch Sensing ASIC" layout="fullWidth" placeholder="blurred" />
                            </Box>
                            <Box p="4" w="50%">
                            <Text fontStyle="italic" maxW="80%">
                                External Unit for Power and Data Telemetry
                            </Text>
                                <StaticImage src="../../../images/external-unit.jpg" alt="External unit" layout="fullWidth" placeholder="blurred" quality="8" />
                            </Box>
                        </Flex>
                            <Box >
                                <Box mb="10">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        MIRA PLATFORM
                                    </Heading>
                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" borderBottom="2px solid black" marginBottom="0.5rem" fontSize="1.25rem">
                                        Specifications
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Amplitude: </strong>
                                            </td>
                                            <td>
                                            0.5μA – 15mA
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Resolution (programmable): </strong></td>
                                            <td>0 – 30kHz</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Input Range (set by resolution): </strong>
                                            </td>
                                            <td>
                                            0.5μs – DC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Sampling Frequency: </strong>
                                            </td>
                                            <td>4.5V or 15V</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Input-Referred Noise: </strong>
                                            </td>
                                            <td>Independent</td>
                                        </tr>
                                    </table>

                                </Box>
                            </Box>
                            <Box p="4" w="60%">
                                <Flex flexDirection="column" justifyContent="center" h="100%">
                                    <Box><StaticImage src="../../../images/myoelectric.jpg" alt="Myoelectric sensing" layout="fullWidth" placeholder="blurred" /></Box>
                                    <Box>
                                        <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" borderBottom="2px solid black" marginBottom="0.5rem" fontSize="1.25rem">
                                            Sample Application: 
                                        </Text>
                                        <Text>
                                            Myoelectric sensing for motor control of prosthesis
                                        </Text>
                                    </Box>
                                </Flex>
                            </Box>

                    </Container>
                </Box>
            
    </Box>
)

export default PowerDataSubsystems