import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"
import { useStaticQuery, graphql } from "gatsby"
import {
  Box,
  Container,
  Flex,
  Text,
  Heading,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react"
import { ExternalLinkIcon } from "@chakra-ui/icons"
import styled from "@emotion/styled"
import { FaRegFilePdf } from "react-icons/fa"

import {
  AdvancedIntegratedCircuits,
  FlexLeadTechnology,
  PowerDataSubsystems
  
} from './ModalContent'

const MultiColContainer = styled.div`
  column-count: 1;

  @media (min-width: 600px) {
    column-count: 2;
    column-gap: 3rem;
    break-inside: avoid;
  }

  @media (min-width: 768px) {
    column-count: 2;
    column-gap: 3rem;
    break-inside: avoid;
  }

  .avoidBreakBox {
    break-inside: avoid;
    margin-bottom: 2rem;
  }
`

const ComponentBlockStaticModalContent = props => {
  const data = useStaticQuery(graphql`
    {
      allPrismicTechnologyPage {
        edges {
          node {
            data {
              page_section {
                section_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED
                        layout: FULL_WIDTH
                        formats: AUTO
                      )
                    }
                  }
                }
                section_category {
                  text
                }
                section_text_content {
                  raw
                }
                section_title {
                  text
                }
                button_1_content {
                  raw
                }
                button_1_media {
                  url
                }
                section_2_button_media {
                  url
                }
                button_1_title {
                  text
                }
                button_2_content {
                  raw
                }
                button_2_title {
                  text
                }
                section_id {
                  text
                }
                extra_paragraph {
                  raw
                }
                extra_paragraph {
                  raw
                }
                section_1_button_content {
                  raw
                }
                section_1_button_title {
                  text
                }
                section_1_content {
                  raw
                }
                section_1_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                    }
                  }
                }
                section_2_button_content {
                  raw
                }
                section_2_button_title {
                  text
                }
                section_2_content {
                  raw
                }
                section_2_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicTechnologyPage.edges[0].node.data.page_section

  const ProductModal = props => {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
      <>
        <Button onClick={onOpen} variant="ctaButtonBlue" isFullWidth my="2">
          {props.buttonTitle} <ExternalLinkIcon ml="2" />
        </Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="outside">
          <ModalOverlay />
          <ModalContent maxW="56rem">
            <ModalHeader
              style={{ zIndex: "unset", textTransform: "uppercase" }}
            >
              {props.buttonTitle.replace("View", "")}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody style={{padding: 0}}>{props.modalBody}</ModalBody>
          </ModalContent>
        </Modal>
      </>
    )
  }

  return (
    <>
      <Box>
        <Container maxW="container.lg" id="component-section">
          {document.map(product => {
            console.log(product.section_id.text)
            return (
              <>
                {/* if there is no product image, make it full width */}
                {!product.section_image.localFile ? (
                  <Box w="100%" my="20">
                    <Text
                      textTransform="uppercase"
                      fontSize="1.25rem"
                      color="shadow.400"
                      id={product.section_id.text}
                    >
                      {product.section_category.text}
                    </Text>
                    <Heading fontWeight="500" as="h2" mb="4">
                      {product.section_title.text}
                    </Heading>
                    <RichText render={product.section_text_content.raw} />

                    {!product.button_1_title.text == "" ? (
                      <Box>
                        <ProductModal 
                      buttonTitle={product.button_1_title.text}
                      modalBody={ product.section_id.text === "integrated-circuits" ? AdvancedIntegratedCircuits : product.section_id.text === "power-and-data-systems" ? PowerDataSubsystems : product.section_id.text === "flex-lead-technology" ? FlexLeadTechnology : null }
                      />
                      </Box>
                    ) : null}
                  </Box>
                ) : (
                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    className="tech-block"
                  >
                    <Box w={{ sm: "100%", md: "50%" }} my="20" pr="4">
                      <Text
                        textTransform="uppercase"
                        fontSize="1.25rem"
                        color="shadow.400"
                        id={product.section_id.text}
                      >
                        {product.section_category.text}
                      </Text>
                      <Heading fontWeight="500" as="h2" mb="4">
                        {product.section_title.text}
                      </Heading>
                      <RichText render={product.section_text_content.raw} />
                      {!product.button_1_title.text == "" ? (
                        <Box>
                          <ProductModal 
                    buttonTitle={product.button_1_title.text}
                    modalBody={ product.section_id.text === "integrated-circuits" ? AdvancedIntegratedCircuits : product.section_id.text === "power-and-data-systems" ? PowerDataSubsystems : product.section_id.text === "flex-lead-technology" ? FlexLeadTechnology : null }
                    />
                        </Box>
                      ) : null}

                      {!product.button_2_title.text == "" ? (
                        <Box>
                          <a
                            href={product.button_1_media.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              variant="ctaButtonBlue"
                              isFullWidth
                              my="2"
                            >
                              {product.button_2_title.text}{" "}
                              <FaRegFilePdf style={{marginLeft: '0.8rem'}} />
                            </Button>
                          </a>
                        </Box>
                      ) : null}
                    </Box>
                    <Box w={{ sm: "100%", md: "50%" }}>
                      <GatsbyImage
                        image={getImage(product.section_image.localFile)}
                        placeholder="blurred"
                        layout="fullWidth"
                        alt=""
                      />
                    </Box>
                  </Flex>
                )}

                {!product.extra_paragraph.raw.length == 0 ? (
                  <>
                    <Box mb="10" className="extra-paragraph">
                      <MultiColContainer>
                        <RichText render={product.extra_paragraph.raw} />
                      </MultiColContainer>
                    </Box>
                    <hr className="small-blue-rule" />
                  </>
                ) : null}

                {!product.section_1_content.raw.length == 0 && (
                  <>
                    <Flex alignItems="center" flexWrap="wrap" mt="10">
                      <Box w={{ sm: "100%", md: "50%" }}>
                        <RichText render={product.section_1_content.raw} />
                        {!product.section_1_button_title.text == "0" ? (
                          <Box>
                            <ProductModal
                              buttonTitle={product.section_1_button_title.text}
                              modalBody={ product.section_id.text === "integrated-circuits" ? AdvancedIntegratedCircuits : product.section_id.text === "power-and-data-systems" ? PowerDataSubsystems : product.section_id.text === "flex-lead-technology" ? FlexLeadTechnology : null }
                            />
                          </Box>
                        ) : null}
                      </Box>
                      <Box w={{ sm: "100%", md: "50%" }}>
                        <GatsbyImage
                          image={getImage(product.section_1_image.localFile)}
                          placeholder="blurred"
                          layout="fullWidth"
                          alt=""
                        />
                      </Box>
                    </Flex>
                  </>
                )}
                {!product.section_2_content.raw.length == 0 && (
                  <>
                    <Flex
                      my="12"
                      pb="20"
                      flexDirection="row-reverse"
                      alignItems="center"
                      flexWrap="wrap"
                      className="tech-block"
                    >
                      <Box w={{ sm: "100%", md: "50%" }}>
                        <RichText render={product.section_2_content.raw} />
                        {!product.section_2_button_title.text == "" ? (
                          <Box>
                            <ProductModal
                              buttonTitle={product.section_2_button_title.text}
                              modalBody={ product.section_id.text === "integrated-circuits" ? AdvancedIntegratedCircuits : product.section_id.text === "power-and-data-systems" ? PowerDataSubsystems : product.section_id.text === "flex-lead-technology" ? FlexLeadTechnology : null }
                            />
                          </Box>
                        ) : null}
                      </Box>
                      <Box w={{ sm: "100%", md: "50%" }}>
                        <GatsbyImage
                          image={getImage(product.section_2_image.localFile)}
                          placeholder="blurred"
                          layout="fullWidth"
                          alt=""
                        />
                      </Box>
                    </Flex>
                  </>
                )}
                <Box></Box>
              </>
            )
          })}
        </Container>
      </Box>
    </>
  )
}

export default ComponentBlockStaticModalContent
