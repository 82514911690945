import React from 'react'
import {
    Box,
    Container,
    Heading,
    Flex,
    Text
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import IrisBackground from '../../../images/iris-background.svg'
import styled from '@emotion/styled'

const BackgroundSection = styled.div`
    background-image: url(${IrisBackground});
    width: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 50%;
`;

const AdvancedIntegratedCircuits = (
    <Box>
        <Container maxW="container.sm">
            <Flex w="100%" justifyContent="center" mb="8">
                <Box w="35%">
                    <StaticImage src="../../../images/stim8@2x.png" layout="fullWidth" placeholder="blurred" alt="Stim 8" />
                </Box>
                <Box verticalAlign="center" ml="4">
                    <Flex flexDirection="column" justifyContent="center" height="100%">
                        <Text style={{fontFamily: 'oswald, sans-serif', marginBottom: 0, fontSize: '1.25rem'}}>
                            STIM (8-CH)
                        </Text>
                    </Flex>
                </Box>
            </Flex>
            <Flex w="100%" justifyContent="center" mb="10">
                <Box w="50%">
                    <StaticImage src="../../../images/record32@2x.png" layout="fullWidth" placeholder="blurred" alt="Stim 8" />
                </Box>
                <Box verticalAlign="center" ml="4">
                    <Flex flexDirection="column" justifyContent="center" height="100%">
                        <Text style={{fontFamily: 'oswald, sans-serif', marginBottom: 0, fontSize: '1.25rem'}}>
                            SENSE (32-CH)
                        </Text>
                    </Flex>
                </Box>
            </Flex>
            </Container>
            <BackgroundSection>
                <Container maxW="container.sm" mb="20">
                    <Heading as="h1" className="card-title">
                        Advanced Integrated Circuits
                    </Heading>
                    <Heading as="h2" className="card-subtitle">
                        Next-Gen ASICs for Sensing &amp; Closed-Loop Stimulation
                    </Heading>
                    <Text>
                    IRIS Biomedical is a leader in high-performance Integrated Circuits for closed-loop neural interface applications. With over 15 years experience developing and deploying ASICs for low-power, low-noise sensing, as well as high-precision, ultra-flexible stimulation, IRIS Biomedical is the only engineering partner with the expertise to design the electronics for your next-generation Neuromodulation or Neural Interface application. 
                    </Text>
                    <Text style={{fontWeight: 700, fontSize: '1.25rem'}}>
                        Notable Features
                    </Text>
                    <ul style={{marginLeft: '1rem'}}>
                        <li>Modular Design – Integrated Circuit functionality can be combined to support Stimulation and Sensing on Every Channel supporting a wide range of closed-loop applications.</li>
                        <li>Artifact Rejection and Sense Recovery – Specially Designed Protection Circuitry allows for Rejection of Large Stimulation Artifacts while Charge Exhausting enables Rapid (&lt;1ms) Recovery of Neural Signals after Stimulation.</li>
                        <li>Flexible Independent Current Outputs – All channels independent current sources. Pulse Waveforms and Pulse-Train Patterns are Completely Programmable.</li>
                    </ul>
                </Container>
                </BackgroundSection>
                <Box>
                    <Container maxW="container.sm">
                        <Flex mb="20">
                            <Box w="50%">
                                <StaticImage src="../../../images/integrated-circuits@2x.png" alt="integrated circuits" layout="fullWidth" placeholder="blurred" />
                            </Box>
                            <Box p="4" w="50%">
                                <Flex flexDirection="column" justifyContent="center" h="100%">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        Integrated Circuits For Implantable Applications
                                    </Heading>
                                </Flex>
                            </Box>
                        </Flex>
                        <Flex mb="8">
                            <Box w="60%">
                                <Box mb="20">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        STIM ASIC (8-CH)
                                    </Heading>
                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" borderBottom="2px solid black" marginBottom="0.5rem" fontSize="1.25rem">
                                        Specifications
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Amplitude: </strong>
                                            </td>
                                            <td>
                                            1μA – 8.5mA
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Frequency: </strong></td>
                                            <td>0 – 30kHz</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Pulse Width: </strong>
                                            </td>
                                            <td>
                                            0.5μs – DC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Compliance: </strong>
                                            </td>
                                            <td>.5V or 15V</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Current Control: </strong>
                                            </td>
                                            <td>Independent</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Interface: </strong>
                                            </td>
                                            <td>
                                            Digital
                                            </td>
                                        </tr>
                                    </table>

                                </Box>
                                <Box mb="20">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        SENSE ASIC (32-CH)
                                    </Heading>
                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" borderBottom="2px solid black" marginBottom="0.5rem" fontSize="1.25rem">
                                        Specifications
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Input Range: </strong>
                                            </td>
                                            <td>
                                            1μA – 8.5mA
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Sample Rate: </strong></td>
                                            <td>up to 30kHz</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>High Pass Corner: </strong>
                                            </td>
                                            <td>
                                            0.3 Hz
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Low Pass Corner </strong>
                                            </td>
                                            <td>7.5 kHz</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Noise: </strong>
                                            </td>
                                            <td>2μV RMS</td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>ADC: </strong>
                                            </td>
                                            <td>
                                            Off-Chip
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Interface: </strong>
                                            </td>
                                            <td>
                                            Digital
                                            </td>
                                        </tr>
                                    </table>
                                </Box>
                            </Box>
                            <Box p="4" w="40%">
                                <Flex flexDirection="column" justifyContent="center" h="100%">
                                    <StaticImage src="../../../images/stim-asic-8ch@2x.png" alt="STIM ASIC" layout="fullWidth" placeholder="blurred" />
                                </Flex>
                            </Box>
                        </Flex>
                    </Container>
                </Box>
            
    </Box>
)

export default AdvancedIntegratedCircuits