import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { RichText } from 'prismic-reactjs'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Container, Flex, Text, Heading, Button, Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure, } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { ClinicalInvestigations } from './ModalContent'
import { FaRegFilePdf } from "react-icons/fa"

const PlatformBlockStaticModalContent = (props) => {
    const data = useStaticQuery(graphql`
    {
      allPrismicTechnologyPlatformSection {
        edges {
          node {
            data {
              page_section {
                section_title {
                  text
                }
                section_text_content {
                  raw
                }
                section_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
                section_id {
                  text
                }
                section_category {
                  text
                }
                section_1_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
                    }
                  }
                }
                button_1_content {
                  raw
                }
                button_1_title {
                  text
                }
                product_section_2_button_title {
                  text
                }
                button_1_media {
                  url
                }
                section_1_button_media {
                  url
                }
                product_section_1_content {
                  raw
                }
                product_section_1_button_title {
                  text
                }
                product_section_1_button_content {
                  raw
                }
              }
            }
          }
        }
      }
    }
  `)

  const document = data.allPrismicTechnologyPlatformSection.edges[0].node.data.page_section

  const ProductModal = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    
    return (
        <>
            <Button onClick={onOpen} variant="ctaButtonBlue" isFullWidth my="2">
                {props.buttonTitle} <ExternalLinkIcon ml="2" />
            </Button>

            <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="outside" size="xl">
        <ModalOverlay />
        <ModalContent maxW="56rem">
          <ModalHeader style={{zIndex: 'unset', textTransform: 'uppercase'}}>{props.buttonTitle.replace('View', '')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {props.modalBody}
          </ModalBody>
        </ModalContent>
      </Modal>
        </>
    )
}

    return (
        <>
        <Box my="20">
            <Container maxW="container.lg">
                {document.map((product) => {
                  return (
                      <>
                      {/* if there is no product image, make it full width */}
                  {!product.section_image.localFile ? (
                      <Box w="100%" my="20" className="product-block">
                      <Text textTransform="uppercase" fontSize="1.25rem" color="shadow.400" id={product.section_id.text}>
                          {product.section_category.text}
                      </Text>
              <Heading fontWeight="500" as="h2" mb="4">{product.section_title.text}</Heading>
              <RichText render={product.section_text_content.raw} />
              
                  { product.button_1_title.text ? (
                      <Box>
                        <ProductModal 
                      buttonTitle={product.button_1_title.text}
                      modalBody={ product.section_id.text === "clinical-investigations" ? ClinicalInvestigations : null }
                      />
              </Box>
                  ) : null }
                  
              </Box>
                  ) : (
                      <Flex flexWrap="wrap"
                      alignItems="center"
                  >
                      <Box w={{sm: '100%', md: '48%'}} my="20" pr="6" className="product-block">
                      <Text textTransform="uppercase" fontSize="1.25rem" color="shadow.400" id={product.section_id.text}>
                          {product.section_category.text}
                      </Text>
              <Heading fontWeight="500" as="h2" mb="4">{product.section_title.text}</Heading>
              <RichText render={product.section_text_content.raw} />
              { product.button_1_title.text ? (
                <Box>
                  <ProductModal 
                      buttonTitle={product.button_1_title.text}
                      modalBody={ product.section_id.text === "clinical-investigations" ? ClinicalInvestigations : null }
                      />
            </Box>
              ) : null }
              {!product.product_section_2_button_title.text == "" ? (
                        <Box>
                          <a
                            href={product.button_1_media.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              variant="ctaButtonBlue"
                              isFullWidth
                              my="2"
                            >
                              {product.product_section_2_button_title.text}{" "}
                              <FaRegFilePdf style={{marginLeft: '0.8rem'}} />
                            </Button>
                          </a>
                        </Box>
                      ) : null}
              
              </Box>
              <Box w={{sm: '100%', md: '50%'}}>
                  <GatsbyImage 
                      image={getImage(product.section_image.localFile)}
                      placeholder="blurred"
                      layout="fullWidth"
                      alt=""
                  />
              </Box>
              </Flex>
                  )}
          <Container maxW="container.lg" className="tech-container">

          {product.product_section_1_content.raw && (
            <Flex my="12" alignItems="center" flexWrap="wrap" flexDirection="row-reverse">
              <Box w={{sm: '100%', md: '48%'}} className="product-block" pr="4">
                <RichText render={product.product_section_1_content.raw} />
                { product.product_section_1_button_title.text ? (
                      <Box>
                        { product.section_1_button_media.url ? (
                          <ProductModal 
                          buttonTitle={product.button_1_title.text}
                          modalBody={ product.section_id.text === "clinical-investigations" ? ClinicalInvestigations : null }
                          />
                        ) :
                        null
                      }
                       {!product.product_section_2_button_title.text == "" ? (
                        <Box>
                          <a
                            href={product.button_1_media.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              className=""
                              variant="ctaButtonBlue"
                              isFullWidth
                              my="2"
                            >
                              {product.product_section_2_button_title.text}{" "}
                              <FaRegFilePdf style={{marginLeft: '0.8rem'}} />
                            </Button>
                          </a>
                        </Box>
                      ) : null}  
                      
              </Box>
                  ) : null }
              </Box>
              <Box w={{sm: '100%', md: '50%'}}>
                <GatsbyImage 
                  image={getImage(product.section_1_image.localFile)}
                  placeholder="blurred"
                  layout="fullWidth"
                  alt=""
                />
              </Box>
            </Flex>
          )}
          <Box>
                  </Box>
      </Container>
      </>
               ) })}
                
            </Container>
            </Box>
        </>
    )
}

export default PlatformBlockStaticModalContent;