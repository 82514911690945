import React from 'react'
import { Box, Container, Heading } from '@chakra-ui/react'

const PageTitle = (props) => {
  return (
    <Box my="40">
      <Container maxW="container.lg">
      <Heading as="h1" textAlign="center" variant="page-title" fontSize={{sm: '3rem', md: '4rem'}} mb="4">
        {props.title}
      </Heading>
      <Heading as="h2" textAlign="center" variant="sub-title">
        {props.subtitle}
      </Heading>
      </Container>
    </Box>
  )
}

export default PageTitle