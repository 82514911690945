import React from 'react'
import {
    Box,
    Container,
    Heading,
    Flex,
    Text
} from '@chakra-ui/react'
import { StaticImage } from 'gatsby-plugin-image'
import IrisBackground from '../../../images/iris-background.svg'
import styled from '@emotion/styled'

const BackgroundSection = styled.div`
    background-image: url(${IrisBackground});
    width: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 50%;
`;

const ClinicalInvestigations = (
    <Box>
        <Container maxW="container.sm">
            <Flex w="100%" justifyContent="center" mb="8">
                <Box w="100%">
                    <StaticImage src="../../../images/athena.jpg" layout="fullWidth" placeholder="blurred" alt="Athena" />
                </Box>
            </Flex>
            </Container>
            <BackgroundSection>
                <Container maxW="container.sm" mb="20">
                    <Heading as="h1" className="card-title">
                        Athena
                    </Heading>
                    <Heading as="h2" className="card-subtitle">
                    32-Channel Stimulation &amp; Sensing Platform for Closed-Loop Neuromodulation
                    </Heading>
                    <Text>IRIS Biomedical’s latest active implantable, the ATHENA, is designed to provide clinical researchers with a highly- configurable platform to support investigation into the latest emerging closed-loop neuromodulation therapies.</Text>
                    <Text>
                    The ATHENA combines IRIS Biomedical’s latest high- performance, programmable stimulation ASIC with its industry- leading sensing technology, for measuring evoked neural signals (ECAPs), EEG, ECoG or EMG to create a research platform with unmatched capabilities. The ATHENA system offers modular processing capabilities to enable rapid development and evaluation of novel stimulation control algorithms.
                    </Text>
                    <Text style={{fontWeight: 700, fontSize: '1.25rem'}}>
                        Features
                    </Text>
                    <ul style={{marginLeft: '1rem'}}>
                        <li>Biocompatible material composition for long-term implantation.</li>
                        <li>Thin, flexible form-factor to minimize impact on neural tissue.</li>
                        <li>Customizable geometry to target specific neural anatomy.</li>
                        <li>Wire-free construction to reduce stiffness and improve durability.</li>
                    </ul>
                    <Text fontStyle="italic">
                    The ATHENA is designed to meet governing design standards for active implanted medical devices and use under IDE approval.
                    </Text>
                </Container>
                </BackgroundSection>
                <Box>
                    <Container maxW="container.sm">
                    <Flex w="100%" justifyContent="center" mb="8">
                <Box w="100%">
                    <StaticImage src="../../../images/athena-platform.jpg" layout="fullWidth" placeholder="blurred" alt="Athena Platform" />
                </Box>
            </Flex>
                            <Box >
                                <Box mb="10">
                                    <Heading as="h2" className="card-subtitle" style={{fontSize: '1.75rem', marginBottom: 0}}>
                                        ATHENA PLATFORM
                                    </Heading>
                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" borderBottom="2px solid black" marginBottom="0.5rem" fontSize="1.25rem">
                                        Specifications
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Channel Count: </strong>
                                            </td>
                                            <td>
                                                32
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Connector: </strong></td>
                                            <td>
                                            4 x 8-Channel (SCS-Style Cylindrical)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Power:</strong>
                                            </td>
                                            <td>
                                            RF (Inductive)
                                            </td>
                                        </tr>
                                    </table>
                                    <Box borderBottom="2px dotted black" my="8" />
                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" marginBottom="0.5rem" fontSize="1.125rem" color="shadow.600">
                                        Stimulation
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Amplitude:  </strong>
                                            </td>
                                            <td>
                                            0.5μA – 15mA
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Frequency: </strong></td>
                                            <td>
                                            0 – 30kHz
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Pulse Width:</strong>
                                            </td>
                                            <td>
                                            1μs – DC
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Compliance:</strong>
                                            </td>
                                            <td>
                                            4.5V to 15V (adjustable)
                                            </td>
                                        </tr>
                                    </table>
                                    <Box borderBottom="2px dotted black" my="8" />

                                    <Text fontWeight="400" textTransform="uppercase" fontFamily="oswald" marginBottom="0.5rem" fontSize="1.125rem" color="shadow.600">
                                        Sensing
                                    </Text>
                                    <table width="100%" className="specs">
                                        <tr>
                                            <td>
                                                <strong>Resolution (programmable):</strong>
                                            </td>
                                            <td>
                                            12-bit (0.125, 0.25, or 0.5 μV/bit)
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><strong>Input Range (set by resolution):</strong></td>
                                            <td>
                                            ± 4, 8, or 12 mV
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Sampling Frequency:</strong>
                                            </td>
                                            <td>
                                            Up to 30kHz
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                            <strong>Input-Referred Noise:</strong>
                                            </td>
                                            <td>
                                            ~2 μVRMS (0.3Hz-7.5kHz bandwidth)
                                            <br />&lt;1 μVRMS (10-200Hz)
                                            </td>
                                        </tr>
                                    </table>

                                </Box>
                            </Box>
                    </Container>
                </Box>
            
    </Box>
)

export default ClinicalInvestigations