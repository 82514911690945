import React from 'react'
import {
    Box,
    Container,
    Flex,
    Text,
    Link,
} from '@chakra-ui/react'

import { ArrowRightIcon } from '@chakra-ui/icons'

const TechnologyMenu = () => (
    <Box>
        <Container maxW="container.lg">
            <Flex justifyContent="space-between" flexWrap="wrap">
            <Box w={{sm: '100%', md: '50%'}} mb={{sm: 8, md: 0}} ml={{sm: 0, mr: '20px'}}>
                <Box>
                    <Text textTransform="uppercase" fontSize="1.25rem" color="shadow.400">
                        Component
                    </Text>
                    <Flex display={{sm: 'none', md: 'flex'}}>
                        <Box mr="8" w="45%">
                            <Box>
                                <Link href="#integrated-circuits" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Integrated Circuits
                                </Link>
                            </Box>
                            <Box>
                                <Link href="#hermetic-packaging" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Hermetic Packaging
                                </Link>
                            </Box>
                        </Box>
                        <Box w="50%">
                            <Box>
                                <Link href="#power-and-data-systems" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Power &amp; Data Systems
                                </Link>
                            </Box>
                            <Box>
                                <Link href="#flex-lead-technology" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Custom Lead Technology
                                </Link>
                            </Box>
                        </Box>
                    </Flex> 
                    {/* Mobile Display */}
            <Box display={{sm: 'block', md: 'none'}}>                        
                            <Box>
                                <Link href="#integrated-circuits" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Integrated Circuits
                                </Link>
                            </Box>
                            <Box>
                                <Link href="#hermetic-packaging" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Hermetic Packaging
                                </Link>
                            </Box>
                        
                        <Box>
                            <Box>
                                <Link href="#power-and-data-systems" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Power &amp; Data Systems
                                </Link>
                            </Box>
                            <Box>
                                <Link href="#flex-lead-technology" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Custom Lead Technology
                                </Link>
                            </Box>
                        </Box>
                        </Box>
                    </Box>              
            </Box>

            

            <Box w={{sm: '100%', md: '45%'}} marginRight={{sm: 0, md: '20px'}}>
                <Text textTransform="uppercase" fontSize="1.25rem" color="shadow.400">
                    Platforms
                </Text>
                <Flex display={{sm: 'none', md: 'block'}}>
                        <Box mr="8">
                            <Box>
                                <Link href="#clinical-investigations" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Clinical Investigations
                                </Link>
                            </Box>
                        </Box>
                        {/* <Box>
                            <Box>
                                <Link href="#pre-clinical-research" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Pre-clinical Research
                                </Link>
                            </Box>
                        </Box> */}
                    </Flex>
                    {/* Mobile Display */}
                    <Box display={{sm: 'block', md: 'none'}}>
                            <Box>
                                <Link href="#clinical-investigations" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Clinical Investigations
                                </Link>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <Link href="#pre-clinical-research" variant="tech-menu">
                                    <ArrowRightIcon fontSize="1rem"/> Pre-Clinical Research
                                </Link>
                            </Box>
                    </Box>
            </Box>
            </Flex>
            <Box mt="20">
                <hr />
            </Box>
            
        </Container>
    </Box>
)

export default TechnologyMenu;