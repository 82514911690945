import React from 'react'
import {
    Box,
    Heading
} from '@chakra-ui/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const SectionHero = (props) => {
    return (
        <Box mb="12">
            <Box position="relative">
                <GatsbyImage image={getImage(props.sectionBackground)} />
                <Box position="absolute" top="50%" left="50%" style={{transform: 'translate(-50%, -50%)'}}>
                <Heading as="h1" color="white" fontSize={{sm: '2rem', md: '3.25rem'}} textAlign="center">
                    {props.sectionHeading}
                </Heading>
            </Box>
            </Box>
            
        </Box>
    )
}

export default SectionHero