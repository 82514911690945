import React from "react"
import { graphql } from 'gatsby'
import Layout from '../components/layout'

import Seo from "../components/seo"

import PageTitle from "../components/PageTitle/PageTitle"
import TechnologyMenu from "../components/TechnologyPage/TechnologyMenu"
//import ComponentBlock from "../components/TechnologyPage/ComponentBlock"
import SectionHero from "../components/TechnologyPage/SectionHero"
//import PlatformBlock from "../components/TechnologyPage/PlatformBlock"
import ComponentBlockStaticModalContent from "../components/TechnologyPage/ComponentBlockStaticModalContent"
import PlatformBlockStaticModalContent from "../components/TechnologyPage/PlatformBlockStaticModalContent"

const IndexPage = ({ data }) => {

  const document = data.allPrismicTechnologyPage.edges[0].node.data
  const pageContent = {
    pageTitle: document.page_title.text,
    subTitle: document.page_subtitle.text,
    sectionBg: document.section_hero_image.localFile,
    sectionHeading: document.section_hero_title.text
  }

  return (
    
  <Layout
    annoucementDisplay="none"
  >
  <Seo title="Iris Biomedical | Technology" />
    <PageTitle 
      title={pageContent.pageTitle}
      subtitle={pageContent.subTitle}
    />

    <TechnologyMenu />
    
    {/* This component takes dynamic modal content:  */}
    {/* <ComponentBlock /> */}
    <ComponentBlockStaticModalContent />
    
    <SectionHero 
      sectionBackground={pageContent.sectionBg}
      sectionHeading={pageContent.sectionHeading}
    />
    {/* <PlatformBlock /> */}
    <PlatformBlockStaticModalContent />
    

  </Layout>
  )
}

export const query = graphql`
  {
    allPrismicTechnologyPage {
      edges {
        node {
          data {
            page_title {
              text
            }
            page_subtitle {
              text
            }
            section_hero_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
            }
            section_hero_title {
              text
            }
          }
        }
      }
    }
  }
`


export default IndexPage
